$signature-color: #4A4A4A;
$light-gray: #F0F0F0;
$medium-gray: #B6B6B6;
$dark-gray: #808080;
$primary-color: #1417ae;
$secondary-color: #40C7F6;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.color-white {
  color: white !important;
}

.bg-md-gray {
  background-color: $medium-gray;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-signature {
  background-color: $signature-color;
}

.bg-theme-primary {
  background-color: $primary-color;
}

.bg-theme-secondary {
  background-color: $secondary-color;
}

.navbar,
.header {
  background-color: $light-gray;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navbar-toggler {
    border: none !important;
  }

  .nav-toggle {
    font-size: 2em;
    right: 0;
    top: 0;
    color: $primary-color;
  }

  nav {
    a {
      display: block;
      padding: 14px 16px;
      color: black;
      text-decoration: none;
    }

    overflow: scroll;
    margin-top: 5%;
    display: none;
    width: 100%;
    text-align: right;
    color: white;
    text-decoration: none;
  }
}

.desktop-nav {
  display: none;
}

#logo {
  width: 200px;
  transition: all 300ms ease 0ms;

  &:focus,
  &:hover {
    transform: scale(1.1);
  }
}

.overlay {
  position: absolute !important;
  z-index: 1 !important;
  width: 100%;
  height: 60vh;
  background-color: rgba(20, 23, 174, 0.25);
}

.header-image {
  width: 100%;
  height: 60vh;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;

  div {
    position: fixed;
    overflow: hidden;
    z-index: -100;
    height: 60vh;
  }
}

.header-image>img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
}

#advisory-service img {
  transform: translate(-50%, -30%);
}

#logistics img {
  transform: translate(-50%, -28%);
}

#sales img {
  transform: translate(-70%, -35%);
}

#channel-marketing img {
  transform: translate(-60%, -30%);
}

.about-us {
  .container {
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  a {
    word-break: break-word;
  }
}

@media screen and (min-width: 374px) and (min-height: 701px) {
  #logistics img {
    transform: translate(-22%, -30%);
  }

  #contract-negotiations img {
    transform: translate(-30%, -50%);
  }
}

@media screen and (min-width: 374px) and (max-height: 700px) {
  #logistics img {
    transform: translate(-50%, -30%);
  }
}

.centered {
  position: absolute;
  top: 35vh;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
}

#home .centered-style {
  text-shadow: 5px 5px 1rem $primary-color;
}

.centered-style {
  font-size: 1.5em;
  color: white !important;
  text-shadow: 5px 5px 1rem $signature-color;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  z-index: 2;
}

.body {
  width: 100%;
  padding: 4% 0;
  background-color: white;
  margin: 0 auto;
}

#mobile-plan {
  font-size: 1.75rem;
}

#desktop-plan {
  display: none;
}

#service-info {
  padding: 5% 1%;
  margin: 5% auto;
  width: 80%;
  z-index: 1;

  h1 {
    text-align: center !important;
  }

  .plan h2 {
    margin: 0 0 3% 0;
    font-weight: 400;
    text-align: center;
  }

  #plan-execute {
    color: white;
    display: block;
    margin: 5% auto;

    .service-block {
      padding: 0 7% 5% 7%;
      background-color: $signature-color;
      border-radius: 7px;
      display: block;
      margin: 5% auto;
    }
  }

  img {
    display: block;
    margin: 10% auto 0 auto;
    width: 30%;
    padding-top: 15%;
  }

  ul {
    display: block;
    list-style: none;

    h4 {
      font-weight: 400;
    }
  }
}

#services {
  margin-top: 150px;

  .row img {
    width: 100%;
    margin: 0;
  }
}

#client-header,
#testimonial-header {
  width: 80%;
  margin: 40% auto 10% auto;

  .centered-style {
    text-shadow: none;
    color: black !important;
  }
}

.row {
  align-items: center;
  align-content: center;
}

.row img,
.row div img {
  margin: 15% auto;
}

.client-logos {
  div {
    width: 75%;
    margin: 0 auto;
  }

  img {
    display: flex;
    width: 100%;
  }

  .md-img img {
    width: 90%;
  }

  .sm-img img {
    width: 75%;
  }
}

.testimonials {
  .testimonial-content {
    background-color: $signature-color;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide {
    border-radius: 7px;
    background-color: white;
    padding: 3rem;
  }

  .slick-dots {
    bottom: -35px;
  }

  .client-logo {
    max-width: 200px;
    margin: 0 0 1.5rem 0;
  }

  .item {
    margin: 5%;
    padding: 2% 7%;
    border-radius: 7px;
    display: inline-block;
    background-color: white;
    overflow: scroll;
    position: relative;
    vertical-align: top;
    border-right: none;

    .client-logo {
      width: 50%;
      padding: 0;
      margin: 10% 0;
    }

    #dh-logo {
      width: 40%;
    }
  }
}

.about-us {
  .headshot {
    width: 100%;
    margin-bottom: 5%;
  }
}

ul {
  list-style-type: none;
  padding: 0 3% 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  position: relative;
  bottom: 0;
  font-size: 0.9em;
  padding: 5%;
  background-color: $light-gray;
  z-index: 50;

  a,
  a:hover {
    color: black;
  }

  #copy {
    padding-top: 10%;
  }
}

@media screen and (min-width: 577px) {
  .slick-slider {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 768px) {
  .header {
    .nav-toggle {
      font-size: 3em;
    }

    .nav-items {
      right: 0;
      width: 41%;
      background-color: $light-gray;
    }

    nav {
      width: 50%;
      padding-bottom: 1.5rem;

      a {
        padding: 14px 50px;
      }

      a:hover {
        background-color: #ddd;
        color: $signature-color;
      }
    }
  }

  .centered-style {
    width: 65%;
    font-size: 2.5em;
  }

  .testimonials {
    .item {
      margin: 2% 15%;

      .client-logo {
        width: 30%;
        padding: 3% 0;
        margin: 0;
      }

      #dh-logo {
        width: 15%;
      }
    }
  }

  #client-header,
  #testimonial-header {
    width: 100%;
    margin: 25% auto 3% auto;
  }

  #logistics img {
    transform: translate(-40%, -30%);
  }

  #contract-negotiations img {
    transform: translate(-40%, -50%);
  }

  #sales img {
    transform: translate(-60%, -35%);
  }
}

@media screen and (max-width: 1199px) {
  .footer {
    #copy {
      padding-top: 5%;
    }
  }
}

@media screen and (max-width: 991px) {
  .home .container {
    img {
      max-width: 200px;
    }

    #cta-image {
      max-width: 75px;
    }
  }

  .footer {
    #copy {
      padding: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .offset-lg-3 {
    margin-top: -4rem;
  }

  .header,
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 0 3%;

    .desktop-nav {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      align-items: center;
      height: 100% !important;

      p {
        display: inline-block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        margin: 0;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 210px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
      }

      .dropdown-content a {
        color: #4A4A4A;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }

      .dropdown:hover,
      p:hover {
        background-color: #1417AE;
        color: white;

        p {
          color: white;
        }
      }

      .dropdown-content a:hover {
        color: white;
        background-color: #40C7F6;
      }

      .dropdown:hover .dropdown-content {
        display: block;
      }
    }

    .mobile-nav {
      display: none;
    }

    #cta-container {
      width: 75px;
      vertical-align: middle;
      margin-left: 5px;
    }

    #cta-image {
      width: 100%;
      vertical-align: middle;
    }
  }

  .header-image div img {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .header {
    .nav-toggle {
      font-size: 3em;
    }
  }

  #home h1 {
    width: 60%;
  }

  #client-header,
  #testimonial-header {
    width: 80%;
    margin: 25% auto 5% auto;
  }

  #service-info {
    width: 70%;
  }

  #desktop-plan {
    display: block;
  }

  #mobile-plan {
    display: none;
  }

  #service-info {
    padding: 0;

    #plan-execute {
      display: flex;
      align-items: stretch;
      margin: 0 auto;

      .service-block {
        width: 45%;
        margin: 0 auto;
      }
    }
  }

  #logistics img,
  #contract-negotiations img {
    transform: translate(-50%, -40%);
  }

  #sales img {
    transform: translate(-50%, -30%);
  }

  #channel-marketing img {
    transform: translate(-50%, -30%);
  }

  .centered-style {
    width: 72%;
  }

  .testimonials {
    .item {
      display: block;
      margin: 2% auto;
      width: 85%;

      .client-logo {
        width: 20%;
        margin: 0;
      }

      #dh-logo {
        width: 15%;
      }
    }
  }

  .about-us {
    .headshot {
      width: 25%;
      display: inline;
    }

    #bob {
      float: right;
      margin-left: 5%;
    }

    #david {
      float: left;
      margin-right: 5%;
      overflow: hidden;
    }

    .bio {
      text-align: justify;
      overflow: hidden;
    }
  }

  .footer {
    padding: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1025px) {
  #advisory-service img {
    transform: translate(-50%, -30%);
  }

  #contract-negotiations img {
    transform: translate(-50%, -50%);
  }

  #logistics img {
    transform: translate(-50%, -40%);
  }

  #sales img {
    transform: translate(-50%, -40%);
  }

  #client-header,
  #testimonial-header {
    margin: 15% auto 5% auto;
  }
}

@media screen and (min-width: 1200px) {
  .offset-lg-3 {
    margin-top: -7rem;
  }
}

@media screen and (min-width: 1600px) {
  #home h1 {
    width: 55%;
  }

  .centered-style {
    width: 65%;
  }
}

@media screen and (min-width: 1900px) {
  .centered-style {
    width: 50%;
  }
}

@media screen and (min-width: 1900px) {
  .centered-style {
    width: 40%;
  }

  #client-header,
  #testimonial-header {
    margin: 10% auto 5% auto;
  }

  .testimonials {
    .item {
      width: 70%;

      .client-logo {
        width: 15%;
      }

      #dh-logo {
        width: 10%;
      }
    }

  }
}

@media screen and (min-width: 2500px) {
  .testimonials {
    .item {
      width: 60%;
    }
  }
}

@media screen and (max-width: 1024px) {
  #services {
    margin-top: 22%;
  }
}

@media screen and (max-width: 576px) {
  #services {
    margin-top: 125px;
  }

  .testimonials .slick-slide {
    border-radius: 0;
  }
}

@media screen and (max-width: 350px) {
  #services {
    margin-top: 115px;
  }
}

@media screen and (max-width: 300px) {
  #services {
    margin-top: 100px;
  }

  .home .container img {
    max-width: 175px;
  }
}